#Nav-Comp{
    box-shadow: 1px 3px 6px rgb(184, 184, 184);
}
.NavDropDownItemContainer{
    margin: 0px 3px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color: #757575;
}
/*
.hero-nav-bar {
  color: black;
}
#home-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url("./homeHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-nav-item {
  color: black;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  padding: 10px;
}
.hero-nav-item:hover {
  color: rgb(0, 0, 0);
  transition: all 0.3s ease-in-out;
  letter-spacing: 1.2px;
}
#home-page-content {
  display: flex;
  flex-direction: column;
}

#hero-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 24px;
  letter-spacing: -0.25px !important;
}
*/
.FAQ-text-container {
    cursor: pointer;
  }
  .home-page-section {
    display: flex;
    align-items: center;
    border-bottom: 8px solid #222;
  }
  
  .home-page-section.noBorder {
    border-bottom: none !important;
  }
  #home-page {
    background-color: rgb(10, 10, 10);
  }
  #home-page-hero-text {
    color: white;
  }
  #home-page-hero-sub-text {
    color: white;
  }
  .footer-text {
    color: #757575;
  }
  #footer-links-ul {
    padding: 0;
    list-style-type: none;
  }
  .footer-link-li {
    color: #757575;
  }
  
  .footer-link {
    color: #757575;
    cursor: pointer;
  }
  
  .footer-link:hover {
    color: white;
    cursor: pointer;
  }
  
  #footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .FAQ-text {
    cursor: pointer;
  }
  .logo{
      width:10px;
      margin-left:25px;
  }
  
  /* desktop */
  @media only screen and (min-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) {
  }
  
  @media only screen and (min-device-width: 1025px) and (max-device-width: 1124px) and (-webkit-min-device-pixel-ratio: 1) {
   
  }
  @media only screen and (min-device-width: 777px) and (max-device-width: 1124px) and (-webkit-min-device-pixel-ratio: 1) {
   
  }
  /* ipad */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    
  }
  /* ipad pro */
  @media only screen and (min-device-width: 800px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
   
  }
  
  /* mobile */
  @media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 1) {
    .logo{
        width:50px;
        margin-left: 0px;
    }
  }
  
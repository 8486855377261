
#CreateProgram-Page{
    margin-top: 50px;   
}

.saveBtn{
    background-color: goldenrod !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.saveBtn:hover{
    background-color: rgb(199, 152, 31) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.saveBtn:focus{
    background-color: rgb(199, 152, 31) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.mdBtn{
    width: 170px;
}
.mdBtnEmpty{
    background-color: #eee !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.mdBtnEmpty:hover {
    background-color: rgb(210, 210, 210) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.mdBtnEmpty:focus {
    background-color: rgb(210, 210, 210) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}

.mdBtnFull{
    background-color: goldenrod !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.mdBtnFull:hover{
    background-color: rgb(199, 152, 31) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
.mdBtnFull:focus{
    background-color: rgb(199, 152, 31) !important;
    color: black !important;
    border-color: rgb(221, 221, 221) !important;
}
#Home-Page{
    background-color: rgb(241, 241, 241);
    min-height: 100%;
    display: flex;
    align-items: center;
}
#LogInFormContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
#LogInForm{
    width: 500px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 3px 6px rgb(184, 184, 184);
    padding: 10px;
    border-radius: 9px;
}
/* desktop */

/* ipad */

/* mobile */
#LeftNav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

}
#welcome-sec{
    margin-bottom:50px;
}
#btn-sec{
    display: flex;
    flex-direction: column;
}
.nav-btn{
    width: 200px;
    margin:10px;
}
.nav-btn.active{
    background-color: #ffc007 !important
}

#UserTableComp{
    margin-top: 50px;   
}

#CreateUserContainer{
    margin-top: 200px;     
}